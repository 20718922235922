import BTCLogo from '../images/BTCLogo.svg'
import EthLogo from '../images/EthLogo.svg'
import BNBLogo from '../images/BNBLogo.svg'
import DogeLogo from '../images/DogeLogo.svg'
import SolLogo from '../images/Solana.svg'
import LitecoinLogo from '../images/LitecoinLogo.svg'
import DashLogo from '../images/DashLogo.svg'
import CardanoLogo from '../images/CardanoLogo.svg'
import MoneroLogo from '../images/XMRLogo.svg'
import UDSTLogo from '../images/TetherLogo.svg'
import USDCLogo from '../images/USDCLogo.svg'
import PolygonLogo from '../images/PolygonLogo.svg'
import ZcashLogo from '../images/ZcashLogo.svg'
import BUSDLogo from '../images/BUSDLogo.svg'
import BatLogo from '../images/BAT.svg'
import DotLogo from '../images/DOT.svg'
import TronLogo from '../images/TronLogo.svg'
import VerseLogo from '../images/VerseLogo.png'
import ArbitrumLogo from '../images/ArbitrumLogo.svg'
import AvalancheLogo from '../images/AvalancheLogo.svg'
import BaseLogo from '../images/BaseLogo.svg'

export interface ManagedPaymentMethod {
  name: string
  logo?: any
  currencyName?: string
  ticker: string
  chainId?: number
  hostChain?: {
    tokenAddress: string
    name: string
    logo: any
  }
  isTestnet?: boolean
  isDisabled?: boolean
  isHidden?: boolean
}

export const getPaymentMethods = (): ManagedPaymentMethod[] => {
  return [
    {
      name: 'Bitcoin',
      logo: BTCLogo,
      ticker: 'BTC'
    },
    {
      name: 'BitcoinTestnet',
      currencyName: 'Bitcoin',
      logo: BTCLogo,
      ticker: 'BTC',
      isTestnet: true
    },
    {
      name: 'Ethereum',
      currencyName: 'Ether',
      logo: EthLogo,
      ticker: 'ETH',
      chainId: 1
    },
    {
      name: 'SepoliaTestnet',
      currencyName: 'Ether',
      logo: EthLogo,
      ticker: 'ETH',
      isTestnet: true,
      chainId: 11155111
    },
    {
      name: 'Solana',
      logo: SolLogo,
      ticker: 'SOL'
    },
    {
      name: 'Polygon',
      chainId: 137,
      currencyName: 'Matic',
      logo: PolygonLogo,
      ticker: 'MATIC'
    },
    {
      name: 'Avalanche',
      chainId: 43114,
      currencyName: 'Avalanche',
      logo: AvalancheLogo,
      ticker: 'AVAX'
    },
    {
      name: 'PolygonTestnet',
      currencyName: 'Matic',
      chainId: 80001,
      logo: PolygonLogo,
      ticker: 'MATIC',
      isTestnet: true,
      isHidden: true
    },
    {
      name: 'BNB',
      chainId: 56,
      currencyName: 'BNB',
      logo: BNBLogo,
      ticker: 'BNB'
    },
    {
      name: 'Arbitrum',
      chainId: 42161,
      currencyName: 'ETH',
      logo: ArbitrumLogo,
      ticker: 'ETH'
    },
    {
      name: 'Base',
      chainId: 8453,
      currencyName: 'ETH',
      logo: BaseLogo,
      ticker: 'ETH'
    },
    {
      name: 'BNBTestnet',
      currencyName: 'BNB',
      chainId: 97,
      logo: BNBLogo,
      ticker: 'BNB',
      isTestnet: true,
      isHidden: true
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 11155111,
      hostChain: {
        tokenAddress: '0xa4fCE8264370437e718aE207805b4e6233638b9E',
        name: 'SepoliaTestnet',
        logo: EthLogo
      },
      isTestnet: true
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 80001,
      hostChain: {
        tokenAddress: '0x8f8b1972eea072C3C228EbE8f9FEADe03927D70F',
        name: 'PolygonTestnet',
        logo: PolygonLogo
      },
      isTestnet: true,
      isHidden: true
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      hostChain: {
        tokenAddress: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
        name: 'SolanaDevnet',
        logo: SolLogo
      },
      isTestnet: true
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 11155111,
      hostChain: {
        tokenAddress: '0xE50d86c6dE38F9754f6777d2925377564Bf79482',
        name: 'SepoliaTestnet',
        logo: EthLogo
      },
      isTestnet: true
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 80001,
      hostChain: {
        tokenAddress: '0x8f8b1972eea072C3C228EbE8f9FEADe03927D70F',
        name: 'PolygonTestnet',
        logo: PolygonLogo
      },
      isTestnet: true,
      isHidden: true
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 1,
      hostChain: {
        tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        name: 'Ethereum',
        logo: EthLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 137,
      hostChain: {
        tokenAddress: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        name: 'Polygon',
        logo: PolygonLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 56,
      hostChain: {
        tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
        name: 'BNB',
        logo: BNBLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      hostChain: {
        tokenAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
        name: 'Solana',
        logo: SolLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 42161,
      hostChain: {
        tokenAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        name: 'Arbitrum',
        logo: ArbitrumLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      chainId: 43114,
      hostChain: {
        tokenAddress: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
        name: 'Avalanche',
        logo: AvalancheLogo
      }
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      hostChain: {
        tokenAddress: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
        name: 'Tron',
        logo: TronLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 1,
      hostChain: {
        tokenAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        name: 'Ethereum',
        logo: EthLogo
      }
    },
    {
      name: 'Bridged USD Coin',
      ticker: 'USDC.e',
      logo: USDCLogo,
      chainId: 137,
      hostChain: {
        tokenAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        name: 'Polygon',
        logo: PolygonLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 137,
      hostChain: {
        tokenAddress: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
        name: 'Polygon',
        logo: PolygonLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      hostChain: {
        tokenAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        name: 'Solana',
        logo: SolLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 56,
      hostChain: {
        tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        name: 'BNB',
        logo: BNBLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 42161,
      hostChain: {
        tokenAddress: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        name: 'Arbitrum',
        logo: ArbitrumLogo
      }
    },
    {
      name: 'Bridged USD Coin',
      ticker: 'USDC.e',
      logo: USDCLogo,
      chainId: 42161,
      hostChain: {
        tokenAddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
        name: 'Arbitrum',
        logo: ArbitrumLogo
      }
    },
    {
      name: 'USD Coin',
      ticker: 'USDC',
      logo: USDCLogo,
      chainId: 8453,
      hostChain: {
        tokenAddress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
        name: 'Base',
        logo: BaseLogo
      }
    },
    {
      name: 'Binance USD',
      ticker: 'BUSD',
      logo: BUSDLogo,
      chainId: 56,
      hostChain: {
        tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        name: 'BNB',
        logo: BNBLogo
      }
    },
    {
      name: 'Basic Attention Token',
      ticker: 'BAT',
      logo: BatLogo,
      chainId: 1,
      hostChain: {
        tokenAddress: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
        name: 'Ethereum',
        logo: EthLogo
      }
    },
    {
      name: 'Basic Attention Token',
      ticker: 'BAT',
      logo: BatLogo,
      hostChain: {
        tokenAddress: 'EPeUFDgHRxs9xxEPVaL6kfGQvCon7jmAWKVUHuux1Tpz',
        name: 'Solana',
        logo: SolLogo
      }
    },
    {
      name: 'Basic Attention Token',
      ticker: 'BAT',
      logo: BatLogo,
      chainId: 137,
      hostChain: {
        tokenAddress: '0x3cef98bb43d732e2f285ee605a8158cde967d219',
        name: 'Polygon',
        logo: PolygonLogo
      }
    },
    {
      name: 'Basic Attention Token',
      ticker: 'BAT',
      logo: BatLogo,
      chainId: 56,
      hostChain: {
        tokenAddress: '0x101d82428437127bf1608f699cd651e6abf9766e',
        name: 'BNB',
        logo: BNBLogo
      }
    },
    {
      name: 'Verse',
      ticker: 'VERSE',
      logo: VerseLogo,
      chainId: 1,
      hostChain: {
        tokenAddress: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
        name: 'Ethereum',
        logo: EthLogo
      }
    },
    {
      name: 'Basic Attention Token',
      ticker: 'BAT',
      logo: BatLogo,
      chainId: 11155111,
      hostChain: {
        tokenAddress: '0x5D684d37922dAf7Aa2013E65A22880a11C475e25',
        name: 'SepoliaTestnet',
        logo: EthLogo
      },
      isTestnet: true
    },
    {
      name: 'SolanaDevnet',
      logo: SolLogo,
      ticker: 'SOL',
      isTestnet: true
    },
    {
      name: 'Polkadot',
      logo: DotLogo,
      ticker: 'DOT'
    },
    {
      name: 'PolkadotTestnet',
      logo: DotLogo,
      ticker: 'WND',
      isTestnet: true
    },
    {
      name: 'TronTestnet',
      logo: TronLogo,
      ticker: 'TRX',
      isTestnet: true
    },
    {
      name: 'Tether',
      ticker: 'USDT',
      logo: UDSTLogo,
      hostChain: {
        tokenAddress: 'TSrUgwP9NPHKMcWPtHRRWcoxFV4JU6R9qk',
        name: 'TronTestnet',
        logo: TronLogo
      },
      isTestnet: true
    },
    {
      name: 'Tron',
      logo: TronLogo,
      ticker: 'TRX'
    },
    // Disabled tokens
    {
      name: 'Cardano',
      logo: CardanoLogo,
      ticker: 'ADA',
      isDisabled: true
    },
    {
      name: 'Monero',
      logo: MoneroLogo,
      ticker: 'XMR',
      isDisabled: true
    },
    {
      name: 'Dogecoin',
      logo: DogeLogo,
      ticker: 'DOGE',
      isDisabled: true
    },
    {
      name: 'Litecoin',
      logo: LitecoinLogo,
      ticker: 'LTC',
      isDisabled: true
    },
    {
      name: 'Dash',
      logo: DashLogo,
      ticker: 'DASH',
      isDisabled: true
    },
    {
      name: 'Zcash',
      logo: ZcashLogo,
      ticker: 'ZEC',
      isDisabled: true
    },
    {
      name: 'ZcashTestnet',
      logo: ZcashLogo,
      ticker: 'ZEC',
      isTestnet: true,
      isDisabled: true
    }

    // Hidden, archived
    // {
    //   name: 'SolanaTestnet',
    //   logo: SolLogo,
    //   ticker: 'SOL',
    //   isTestnet: true,
    //   isHidden: true
    // }
  ]
}

export const getMethod = (network: string, token?: string): ManagedPaymentMethod => {
  const method = getPaymentMethods().find(m => {
    if (token) {
      return m.hostChain?.tokenAddress.toLowerCase() === token.toLowerCase() &&
       network.toLowerCase() === m.hostChain?.name.toLowerCase()
    } else {
      return m.name.toLowerCase() === network.toLowerCase()
    }
  })

  if (!method) {
    return {
      name: 'Unknown',
      logo: null,
      ticker: 'Unknown'
    }
  }

  return method
}

export const getMethodLogoByTicker = (ticker: string): any => {
  const method = getPaymentMethods().find(m => m.ticker === ticker)

  if (!method) {
    return null
  }

  return method.logo
}

export const testNetworks = [...new Set(
  getPaymentMethods().filter(a => a.isTestnet).map(a => a.hostChain?.name ?? a.name)
)]

export const prodNetworks = [...new Set(
  getPaymentMethods().filter(a => !a.isTestnet).map(a => a.hostChain?.name ?? a.name)
)]
