import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import AssetWithdrawalModal, { WithdrawableAsset } from '../../components/AssetWithdrawalModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import ExportBalanceForm from '../../components/ExportBalanceForm'
import LoadingBar from '../../components/LoadingBar'
import Modal from '../../components/Modal'
import Dots from '../../icons/Dots'
import Treasury from '../../state/Treasury'
import { RADOM_COLORS } from '../../util/Constants'
import { getMethod } from '../../util/Managed'
import { useTestMode, formatCurrency } from '../../util/Util'
import { TokenImage, TokenTitle } from '../Funds'
import User from '../../state/User'

const Overview = observer((): ReactElement => {
  const [testMode] = useTestMode()

  const [modalOpen, setModalOpen] = useState(false)
  const [exportBalanceModalOpen, setExportBalanceModalOpen] = useState(false)
  const [assetToWithdraw, setAssetToWithdraw] = useState<WithdrawableAsset>({
    maxAmount: '',
    network: 'Ethereum'
  })

  useEffect(() => {
    Treasury.loadBalances()
  }, [])

  const onClickWithdraw = (w: WithdrawableAsset): void => {
    setAssetToWithdraw(w)
    setModalOpen(true)
  }

  return <>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <PageHeader title='Balances' subTitle='View and withdraw your crypto balances' />
      <Dropdown
        noChevron
        selectedContent={<Dots width={15} />}
        dropdownContent={
          <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
            <DropdownItem onClick={() => setExportBalanceModalOpen(true)}>Export balance report</DropdownItem>
          </div>
        }
      />
    </div>
    <Table
      borderless
      headers={['Asset', 'Exchange rate', 'Network', 'Balance', ''].map(h => <span key={h}>{h}</span>)}
      items={
        Treasury.managedBalances
          .filter(b => Number(b.balance) > 0)
          .filter(b => {
            const m = getMethod(b.network, b.token)?.isTestnet
            return testMode ? m : !m
          })
          .sort((a, b) => {
            const conversionA = Treasury.priceQuotes
              .find(p => p.from.toLowerCase() === getMethod(a.network, a.token).ticker.toLowerCase())
            const conversionB = Treasury.priceQuotes
              .find(p => p.from.toLowerCase() === getMethod(b.network, b.token).ticker.toLowerCase())

            const aV = Number(a.balance) * (conversionA?.value || 1)
            const bV = Number(b.balance) * (conversionB?.value || 1)

            return bV - aV
          }).map(b => {
            const method = getMethod(b.network, b.token)
            const conversion = Treasury.priceQuotes
              .find(p => p.from.toLowerCase() === method.ticker.toLowerCase())

            return [
              <div key="token" style={{ display: 'flex', flexDirection: 'column', fontSize: 14 }}>
                <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                  <TokenImage imageUrl={method.logo} />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <TokenTitle>{method.currencyName ?? method.name}</TokenTitle>
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                      <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                        {method.ticker}
                      </span>
                    </div>
                  </div>
                </div>
              </div>,
              <div key='conversion'>
                {
                  conversion
                    ? <div style={{
                      color: RADOM_COLORS.BLACK,
                      borderRadius: 5,
                      padding: '0 5px'
                    }}>
                      {formatCurrency(Number(conversion.value), User.organization?.defaultCurrency ?? 'USD')} {User.organization?.defaultCurrency ?? 'USD'}
                    </div>
                    : <LoadingBar style={{ height: 16 }} />
                }
              </div>,
              <div key="chain" style={{ display: 'flex', alignItems: 'center', gap: 5, whiteSpace: 'pre' }}>
                {<img src={method.hostChain?.logo ?? method.logo} style={{ maxHeight: 20, maxWidth: 20 }} />}
                <span>{method.hostChain?.name ?? method.name}</span>
              </div>,
              <div key="value" style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {
                  new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 18
                  }).format(b.balance as any)
                }
                {
                  conversion
                    ? <span style={{ opacity: 0.5 }}>
                  ≈ {formatCurrency(Number(b.balance) * (conversion.value), User.organization?.defaultCurrency ?? 'USD')}
                    </span>
                    : <LoadingBar style={{ height: 10 }} />
                }
              </div>,
              <div key="withdraw">
                <PrimaryButton
                  onClick={() => {
                    onClickWithdraw({
                      network: b.network,
                      token: b.token,
                      maxAmount: b.balance
                    })
                  }}>
                  Withdraw
                </PrimaryButton>
              </div>
            ]
          })
      }
      isLoading={Treasury.loading}
      emptyPlaceholderText="No funds yet"
    />

    {
      modalOpen &&
      <AssetWithdrawalModal
        modalOpen={modalOpen}
        onModalOpen={open => setModalOpen(open)}
        assetToWithdraw={assetToWithdraw}
      />
    }

    {
      exportBalanceModalOpen &&
      <Modal visible={exportBalanceModalOpen} onClose={() => setExportBalanceModalOpen(false)} title='Export balance report'>
        <ExportBalanceForm />
      </Modal>
    }
  </>
})

export default Overview
