"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.psRl = exports.opacityOverlay = exports.npe = exports.jsb = exports.jfe = exports.jc = exports.inputWrap = exports.inputLabel = exports.inputDropdown = exports.input = exports.handle = exports.gradientHandleWrap = exports.gradientHandle = exports.df = exports.degreeInput = exports.controlBtnsWrap = exports.controlBtn = exports.checkered = exports.canvasWrapper = exports.cResize = exports.cCross = exports.borderBox = exports.ac = void 0;
var df = {
  display: 'flex'
};
exports.df = df;
var ac = {
  alignItems: 'center'
};
exports.ac = ac;
var jc = {
  justifyContent: 'center'
};
exports.jc = jc;
var jfe = {
  justifyContent: 'flex-end'
};
exports.jfe = jfe;
var jsb = {
  justifyContent: 'space-between'
};
exports.jsb = jsb;
var psRl = {
  position: 'relative'
};
exports.psRl = psRl;
var cCross = {
  cursor: 'crosshair'
};
exports.cCross = cCross;
var canvasWrapper = {
  borderRadius: 6,
  overflow: 'hidden',
  height: 294
};
exports.canvasWrapper = canvasWrapper;
var cResize = {
  cursor: 'ew-resize'
};
exports.cResize = cResize;
var opacityOverlay = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 10
};
exports.opacityOverlay = opacityOverlay;
var borderBox = {
  boxSizing: 'border-box'
};
exports.borderBox = borderBox;
var handle = {
  position: 'absolute',
  border: '2px solid white',
  borderRadius: '50%',
  boxShadow: '0px 0px 3px rgba(0,0,0,.5)',
  width: 18,
  height: 18,
  zIndex: 1000,
  transition: 'all 10ms linear',
  boxSizing: 'border-box'
};
exports.handle = handle;
var gradientHandleWrap = {
  position: 'absolute',
  zIndex: 10000,
  top: -2,
  outline: 'none'
};
exports.gradientHandleWrap = gradientHandleWrap;
var gradientHandle = {
  border: '2px solid white',
  borderRadius: '50%',
  boxShadow: '0px 0px 3px rgba(0,0,0,.5)',
  width: 18,
  height: 18,
  boxSizing: 'border-box'
};
exports.gradientHandle = gradientHandle;
var npe = {
  pointerEvents: 'none'
};
exports.npe = npe;
var degreeInput = {
  height: 24,
  borderRadius: 4,
  border: 'none',
  outline: 'none',
  textAlign: 'center',
  width: 34,
  fontWeight: 500,
  color: 'rgb(50, 49, 54)',
  fontSize: 13,
  background: 'transparent'
};
exports.degreeInput = degreeInput;
var inputWrap = {
  height: 32,
  borderRadius: 6,
  border: '1px solid #bebebe',
  width: '100%',
  padding: 2,
  outline: 'none',
  color: 'black',
  fontWeight: 400,
  textAlign: 'center',
  boxSizing: 'border-box'
};
exports.inputWrap = inputWrap;
var inputLabel = {
  textAlign: 'center',
  lineHeight: 1.2,
  fontWeight: 700,
  color: 'rgb(86,86,86)',
  fontSize: 11
};
exports.inputLabel = inputLabel;
var controlBtn = {
  paddingLeft: 8,
  paddingRight: 8,
  lineHeight: 1,
  borderRadius: 4,
  fontWeight: 700,
  color: '',
  fontSize: 12,
  height: 24,
  transition: 'all 160ms ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255,255,255,0)',
  boxShadow: '1px 1px 3px rgba(0,0,0,0)'
};
exports.controlBtn = controlBtn;
var controlBtnsWrap = {
  height: 28,
  background: '#e9e9f5',
  borderRadius: 6,
  padding: 2
};
exports.controlBtnsWrap = controlBtnsWrap;
var inputDropdown = {
  position: 'absolute',
  right: -2,
  top: 34,
  padding: 5,
  background: '#e9e9f5',
  zIndex: 100000000,
  borderRadius: 6,
  boxShadow: '1px 1px 14px 1px rgba(0,0,0,.25)'
};
exports.inputDropdown = inputDropdown;
var input = {
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
};
exports.input = input;
var checkered = {
  // background: '-webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), white',
  // background: '-moz-linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), white',
  background: 'linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.18) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 75%, rgba(0, 0, 0, 0.18) 0), white',
  backgroundRepeat: 'repeat, repeat',
  backgroundPosition: '0px 0, 7px 7px',
  WebkitTransformOrigin: '0 0 0',
  transformOrigin: '0 0 0',
  WebkitBackgroundOrigin: 'padding-box, padding-box',
  backgroundOrigin: 'padding-box, padding-box',
  WebkitBackgroundClip: 'border-box, border-box',
  backgroundClip: 'border-box, border-box',
  WebkitBackgroundSize: '14px 14px, 14px 14px',
  backgroundSize: '14px 14px, 14px 14px',
  WebkitBoxShadow: 'none',
  boxShadow: 'none',
  textShadow: 'none',
  WebkitTransition: 'none',
  MozTransition: 'none',
  OTransition: 'none',
  transition: 'none',
  WebkitTransform: 'scaleX(1) scaleY(1) scaleZ(1)',
  transform: 'scaleX(1) scaleY(1) scaleZ(1)',
  borderRadius: 10
};
exports.checkered = checkered;