import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

const LinkExternalWrapper = styled.svg`
  transition: 0.2s ease all;
  ${props => !props.stroke && `stroke: ${RADOM_COLORS.GRAY_DARK};`}

  :hover {
    stroke: ${RADOM_COLORS.ORANGE};
  }
` as any

export default function LinkExternal(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <LinkExternalWrapper viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 15L20 4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 4H20V9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </LinkExternalWrapper>
}
