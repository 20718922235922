import EthLogo from '../images/EthLogo.svg'
import ArbitrumLogo from '../images/ArbitrumLogo.svg'
import AuroraLogo from '../images/AuroraLogo.svg'
import MetisLogo from '../images/MetisLogo.svg'
import PolygonLogo from '../images/PolygonLogo.svg'
import AvalancheLogo from '../images/AvalancheLogo.svg'
import BNBLogo from '../images/BNBLogo.svg'
import OptimismLogo from '../images/OptimismLogo.svg'
import FantomLogo from '../images/FantomLogo.svg'
import RadomLogo from '../images/RadomLogo.svg'
import ScrollLogo from '../images/ScrollLogo.svg'
import OKXLogo from '../images/OKXLogo.png'
import { EXPLORER_URLS, IS_LOCAL, IS_PROD, IS_TEST, IS_DEV } from './Constants'

export interface Chain {
  name: string
  chainId: number
  rpcURL: string
  symbol: string
  logo?: any
  isTestnet?: boolean
}

export const Chains = {
  RadomDevelopment: {
    name: 'Radom Development',
    logo: RadomLogo,
    chainId: 31337,
    symbol: 'ETH',
    rpcURL: 'https://dzcd0iqtbql7u.cloudfront.net',
    isTestnet: true
  },
  Ethereum: {
    name: 'Ethereum',
    logo: EthLogo,
    chainId: 1,
    symbol: 'ETH',
    rpcURL: 'https://rpc.ankr.com/eth'
  },
  Goerli: {
    name: 'Goerli Testnet',
    logo: EthLogo,
    chainId: 5,
    symbol: 'ETH',
    rpcURL: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    isTestnet: true
  },
  BSC: {
    name: 'Binance Smart Chain',
    logo: BNBLogo,
    chainId: 56,
    symbol: 'BNB',
    rpcURL: 'https://bsc-dataseed.binance.org'
  },
  Avalanche: {
    name: 'Avalanche',
    logo: AvalancheLogo,
    chainId: 43114,
    symbol: 'AVAX',
    rpcURL: 'https://api.avax.network/ext/bc/C/rpc'
  },
  Arbitrum: {
    name: 'Arbitrum',
    logo: ArbitrumLogo,
    chainId: 42161,
    symbol: 'ETH',
    rpcURL: 'https://arb1.arbitrum.io/rpc'
  },
  'Arbitrum Rinkeby': {
    name: 'Arbitrum Rinkeby',
    logo: ArbitrumLogo,
    chainId: 421611,
    symbol: 'ETH',
    rpcURL: 'https://rinkeby.arbitrum.io/rpc',
    isTestnet: true
  },
  Optimism: {
    name: 'Optimism',
    logo: OptimismLogo,
    chainId: 10,
    symbol: 'ETH',
    rpcURL: 'https://mainnet.optimism.io'
  },
  Fantom: {
    name: 'Fantom',
    logo: FantomLogo,
    chainId: 250,
    symbol: 'FTM',
    rpcURL: 'https://rpc.fantom.network'
  },
  'Fantom Testnet': {
    name: 'Fantom Testnet',
    logo: FantomLogo,
    chainId: 4002,
    symbol: 'FTM',
    rpcURL: 'https://rpc.ankr.com/fantom_testnet',
    isTestnet: true
  },
  Aurora: {
    name: 'Aurora',
    logo: AuroraLogo,
    chainId: 1313161554,
    symbol: 'ETH',
    rpcURL: 'https://mainnet.aurora.dev'
  },
  'Aurora Testnet': {
    name: 'Aurora Testnet',
    logo: AuroraLogo,
    chainId: 1313161555,
    symbol: 'ETH',
    rpcURL: 'https://testnet.aurora.dev',
    isTestnet: true
  },
  Metis: {
    name: 'Metis',
    logo: MetisLogo,
    chainId: 1088,
    symbol: 'METIS',
    rpcURL: 'https://andromeda.metis.io/?owner=1088'
  },
  'Metis Testnet': {
    name: 'Metis Testnet',
    logo: MetisLogo,
    chainId: 588,
    symbol: 'METIS',
    rpcURL: 'https://stardust.metis.io/?owner=588',
    isTestnet: true
  },
  Mumbai: {
    name: 'PolygonTestnet',
    logo: PolygonLogo,
    chainId: 80001,
    symbol: 'MATIC',
    rpcURL: 'https://rpc-mumbai.maticvigil.com',
    isTestnet: true
  },
  Sepolia: {
    name: 'SepoliaTestnet',
    logo: EthLogo,
    chainId: 11155111,
    symbol: 'ETH',
    rpcURL: 'https://rpc.sepolia.org',
    isTestnet: true
  },
  'Avalanche Testnet': {
    name: 'Avalanche Testnet',
    logo: AvalancheLogo,
    chainId: 43113,
    symbol: 'AVAX',
    rpcURL: 'https://rpc.ankr.com/avalanche_fuji',
    isTestnet: true
  },
  'Scroll Alpha Testnet': {
    name: 'Scroll Testnet',
    logo: ScrollLogo,
    chainId: 534353,
    symbol: 'ETH',
    rpcURL: 'https://alpha-rpc.scroll.io/l2',
    isTestnet: true
  },
  Polygon: {
    name: 'Polygon',
    logo: PolygonLogo,
    chainId: 137,
    symbol: 'MATIC',
    rpcURL: 'https://polygon.llamarpc.com'
  },
  OKTC: {
    name: 'OKT Chain',
    logo: OKXLogo,
    chainId: 66,
    symbol: 'OKT',
    rpcURL: 'https://exchainrpc.okex.org'
  }
}

const testChains = [
  Chains['Fantom Testnet'],
  Chains.Mumbai,
  Chains.Sepolia,
  Chains['Aurora Testnet'],
  Chains['Avalanche Testnet'],
  Chains['Scroll Alpha Testnet']
]

const prodChains = [
  Chains.Ethereum,
  Chains.Polygon,
  Chains.BSC,
  Chains.Aurora,
  Chains.Fantom,
  Chains.Avalanche,
  Chains.OKTC
]

export const getTestChains = (): Chain[] => {
  if (IS_PROD || IS_TEST) {
    return testChains
  }

  if (IS_LOCAL) {
    return [Chains.RadomDevelopment, ...testChains]
  }

  return testChains
}

export const getChains = (): Chain[] => {
  if (IS_LOCAL) {
    return [Chains.RadomDevelopment, ...testChains, ...prodChains]
  }

  if (IS_DEV) {
    return [Chains.RadomDevelopment]
  }

  if (IS_TEST) {
    return testChains
  }

  return [...prodChains, ...testChains]
}

const addressType = (network: string, initialType: 'address' | 'tx'): string => {
  if (network === 'PolkadotTestnet' || network === 'Polkadot') {
    if (initialType === 'tx') {
      return 'extrinsic'
    }
  }

  if (network === 'TronTestnet' || network === 'Tron') {
    if (initialType === 'tx') {
      return 'transaction'
    }
  }

  return initialType
}

export const formatExplorerUrl = (network: string, type: 'address' | 'tx', hash: string): string => {
  if (network === 'TronTestnet' || network === 'Tron') {
    hash = hash.replace('0x', '')
  }

  const explorerUrl = EXPLORER_URLS[network]
  let url = `${explorerUrl}/${addressType(network, type)}/${hash}`

  if (network === 'SolanaDevnet') {
    url = url + '?cluster=devnet'
  }
  return url
}

export function getChainById(id: number): Chain {
  const chain = Object.values(Chains).find(chain => chain.chainId === id)

  if (!chain) {
    return {
      logo: null,
      name: 'Unknown',
      chainId: id,
      symbol: 'Unknown',
      rpcURL: ''
    }
  }

  return chain
}
