"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.config = void 0;
var config = {
  barSize: 18,
  crossSize: 18,
  inputSize: 40,
  delay: 150,
  defaultColor: 'rgba(175, 51, 242, 1)',
  defaultGradient: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'
};
exports.config = config;