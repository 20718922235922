import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { IconButton } from './Button'
import Dropdown, { DropdownContentBody, DropdownContentHeader, DropdownTextInput } from './Dropdown'
import Close from '../icons/Close'
import Spinner from './Spinner'

interface IProps<T> {
  itemArray: T[]
  selectedItems: T[]
  setSelectedItems: (items: T[]) => void
  keyExtractor: (item: T) => string
  labelExtractor: (item: T) => React.ReactNode
  placeholder?: string
  dropdownPlaceholder?: string
  disabled?: boolean
  onNewItem?: () => void
  hideSelectedContent?: boolean
  isSingleSelect?: boolean
  headers?: React.ReactElement[]
  isLoading?: boolean
}

const DropdownListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  
  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }
`

const DropdownSelectedItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  padding: 8px;
`

const DropdownPlaceholder = styled.div`
  padding: 20px;
  color: ${RADOM_COLORS.GRAY_DARKER};
`

const SearchMultiselect = <T extends unknown>(props: IProps<T>): React.ReactElement => {
  const [query, setQuery] = useState('')
  const dropdownRef = useRef<Dropdown>(null)
  const queryInputRef = useRef<HTMLInputElement>(null)

  const setDropdown = (open: boolean): void => {
    dropdownRef.current?.setState({ open })
  }

  const itemSearchFilter = (item: T): boolean => {
    const probe = props.keyExtractor(item).toLowerCase()
    return !props.selectedItems.find(i => props.keyExtractor(i).toLowerCase().includes(probe)) &&
    (query === '' || probe.includes(query.toLowerCase()))
  }

  const dropdownItems = useMemo(() => {
    return props.itemArray
      .filter(itemSearchFilter)
  }, [props.itemArray, props.selectedItems, query])

  const onQueryChange = (e: React.ChangeEvent): void => {
    const val = (e.target as HTMLInputElement).value
    if (val !== '') {
      setDropdown(true)
    }
    setQuery(val)
  }

  const onItemSelect = (e: React.MouseEvent | React.KeyboardEvent, item: T): void => {
    e.preventDefault()
    e.stopPropagation()

    setQuery('')
    setDropdown(false)
    if (props.isSingleSelect) {
      props.setSelectedItems([item])
    } else {
      props.setSelectedItems([...props.selectedItems, item])
    }
  }

  const onItemDelete = (e: React.MouseEvent, item: T): void => {
    e.preventDefault()
    e.stopPropagation()

    props.setSelectedItems(
      props.selectedItems
        .filter(c => props.keyExtractor(c) !== props.keyExtractor(item))
    )
  }

  const onClickDropdown = (): void => {
    queryInputRef.current?.focus()
  }
  return (
    <div style={{ fontSize: 14 }}>
      {
        <Dropdown
          ref={dropdownRef}
          onClick={onClickDropdown}
          disabled={props.disabled}
          selectedContent={
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 8 }}>
              {
                props.isLoading && <Spinner />
              }
              {
                (props.selectedItems.length === 0 ||
                props.hideSelectedContent) &&
                !props.isLoading &&
                <span>{props.placeholder ?? 'Select items'}</span>
              }
              {
                props.selectedItems.length > 0 &&
                !props.hideSelectedContent &&
                !props.isLoading &&
                props.selectedItems.map((item, i) =>
                  props.isSingleSelect
                    ? <div key={i}>{props.labelExtractor(item)}</div>
                    : <DropdownSelectedItem key={i}>
                      {props.labelExtractor(item)}
                      {
                        !props.disabled &&
                      <IconButton style={{ padding: 5 }} onClick={(e) => onItemDelete(e, item)}>
                        <Close style={{ width: 10, pointerEvents: 'none' }} />
                      </IconButton>
                      }
                    </DropdownSelectedItem>
                )
              }
            </div>
          }
          dropdownContent={
            <div>
              <DropdownContentHeader style={{ borderBottom: `1px solid ${RADOM_COLORS.GRAY_DARK}` }}>
                <DropdownTextInput
                  ref={queryInputRef}
                  type="text"
                  value={query}
                  onChange={onQueryChange}
                  placeholder={'Search...'}
                />
                {
                  query !== '' &&
                  <IconButton
                    style={{ position: 'absolute', right: 10, padding: 5 }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setQuery('')
                    }}>
                    <Close style={{ width: 10, pointerEvents: 'none' }} />
                  </IconButton>
                }
              </DropdownContentHeader>
              {
                props.headers?.map((h, i) =>
                  <DropdownContentHeader onClick={() => setDropdown(false)} key={i}>
                    {h}
                  </DropdownContentHeader>
                )
              }
              <DropdownContentBody>
                {
                  !dropdownItems.length &&
                  <DropdownPlaceholder>{props.dropdownPlaceholder ?? 'No Items'}</DropdownPlaceholder>
                }
                {
                  dropdownItems
                    .map(item =>
                      <DropdownListItem
                        key={props.keyExtractor(item)}
                        onClick={(e) => onItemSelect(e, item)}
                      >
                        {props.labelExtractor(item)}
                      </DropdownListItem>
                    )
                }
              </DropdownContentBody>
            </div>
          }
        />
      }
    </div>
  )
}

export default SearchMultiselect
