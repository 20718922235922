import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { IconButton, PrimaryButton } from '../../components/Button'
import { ManagedPaymentMethodSelector } from '../../components/ManagedMethodMultiselector'
import Close from '../../icons/Close'
import ColorPicker from 'react-best-gradient-color-picker'
import { ManagedPaymentMethod, getMethod } from '../../util/Managed'
import Preview from '../../components/Preview'
import NavbarController from '../../state/NavbarController'
import { PAY_BASE_URL, RADOM_COLORS } from '../../util/Constants'
import { InputLabel, OptionalLabel, TextInput, TextInputWithLabel } from '../../components/Input'
import { ImageUploadButton } from '../../components/ProductForm'
import { errorToast, successToast } from '../../util/Util'
import Dropdown from '../../components/Dropdown'
import Radom, { IDonationLink, InputFieldDataType } from '../../state/Radom'
import LoadingBar from '../../components/LoadingBar'
import SearchMultiselect from '../../components/SearchMultiselect'
import { Currencies } from '../../util/Currencies'
import Switch from '../../components/Switch'

export const EditDonationLink = (): ReactElement => {
  const { donationLinkId } = useParams()
  const navigate = useNavigate()

  const [methods, setSelectedMethods] = useState<ManagedPaymentMethod[]>([])
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  const imageFileInput = useRef<HTMLInputElement>(null)
  const [logoImgBytes, setLogoImgBytes] = useState<string>()
  const [logoImageFile, setLogoImageFile] = useState<File>()

  const bannerFileInput = useRef<HTMLInputElement>(null)
  const [bannerImgBytes, setBannerImgBytes] = useState<string>()
  const [bannerImageFile, setBannerImageFile] = useState<File>()

  const [buttonColor, setButtonColor] = useState('black')
  const [name, setName] = useState('')
  const [currency, setCurrency] = useState('USD')
  const [tosUrl, setTosUrl] = useState<string>()

  const [logoPreviewUrl, setLogoPreviewUrl] = useState<string | undefined>()
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState<string | undefined>()

  useEffect(() => {
    const fetchDonationLink = async (): Promise<void> => {
      try {
        const donationLink = await Radom.getDonationLink(donationLinkId ?? '')
        setName(donationLink.name)
        setCurrency(donationLink.currency)
        setButtonColor(donationLink.primaryButtonColor)
        setSelectedMethods(donationLink.methods.map(m => getMethod(m.network, m.token)) ?? [])
        setLogoImgBytes(donationLink.imageUrl)
        setBannerImgBytes(donationLink.bannerImageUrl)
        setLogoPreviewUrl(donationLink.imageUrl)
        setBannerPreviewUrl(donationLink.bannerImageUrl)
        setTosUrl(donationLink.tosUrl && donationLink.tosUrl.length > 0 ? donationLink.tosUrl : undefined)
      } catch (err) {
        errorToast('Failed to load donation link data')
        navigate('/donation_links')
      }
      setInitialLoading(false)
    }

    if (donationLinkId) {
      fetchDonationLink()
    } else {
      errorToast('Invalid donation link ID')
      navigate('/donation_links')
    }
  }, [donationLinkId, navigate])

  const onImageFileChange = async (
    imageFileInput: React.RefObject<HTMLInputElement>,
    setBytes: React.Dispatch<React.SetStateAction<string | undefined>>,
    setImageFile: React.Dispatch<React.SetStateAction<File | undefined>>,
    setPreviewUrl: React.Dispatch<React.SetStateAction<string | undefined>>
  ): Promise<void> => {
    const fileList = imageFileInput.current?.files
    if (fileList && fileList.length > 0) {
      const imageFile = fileList[0]
      if (imageFile.size > 4000000) {
        errorToast('File size must be less than 4MB')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(imageFile)
      reader.onloadend = () => {
        const base64data = reader.result
        setBytes(base64data as any)
        setImageFile(imageFile)

        const previewUrl = URL.createObjectURL(imageFile)
        setPreviewUrl(previewUrl)
      }
    }
  }

  const handleUpdateDonationLink = async (donationLink: IDonationLink): Promise<void> => {
    setLoading(true)
    try {
      await Radom.updateDonationLink(donationLink)

      if (logoImageFile) {
        const formData = new FormData()
        formData.append('entityId', donationLink.id)
        formData.append('imageType', 'donationLinkAvatar')
        formData.append('image', logoImageFile)
        await Radom.uploadImage(formData)
      }

      if (bannerImageFile) {
        const formData = new FormData()
        formData.append('entityId', donationLink.id)
        formData.append('imageType', 'donationLinkBackground')
        formData.append('image', bannerImageFile)
        await Radom.uploadImage(formData)
      }

      successToast('Successfully updated donation link')
      navigate('/donation_links')
    } catch (err) {
      console.error(err)
      errorToast(err.response ? err.response.data.error || err.response.data : err.message)
    }
    setLoading(false)
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    const donationLink: IDonationLink = {
      id: donationLinkId ?? '',
      methods: methods.map(m => ({ network: m.hostChain?.name || m.name, token: m.hostChain?.tokenAddress })),
      name,
      currency,
      primaryButtonColor: buttonColor,
      createdAt: new Date(),
      updatedAt: new Date(),
      inputFields: [
        { dataType: InputFieldDataType.String, inputLabel: 'First Name', isRequired: true },
        { dataType: InputFieldDataType.String, inputLabel: 'Last Name', isRequired: true },
        { dataType: InputFieldDataType.Email, inputLabel: 'Email address', isRequired: true },
        { dataType: InputFieldDataType.String, inputLabel: 'Phone', isRequired: true },
        { dataType: InputFieldDataType.Address, inputLabel: 'Address', isRequired: true }
      ],
      url: '',
      tosUrl
    }

    handleUpdateDonationLink(donationLink)
  }

  const previewData = useMemo(() => {
    const data = {
      name,
      currency,
      primaryButtonColor: buttonColor,
      imageUrl: logoImgBytes ?? logoPreviewUrl,
      bannerImageUrl: bannerImgBytes ?? bannerPreviewUrl,
      methods: methods.map(m => ({ network: m.hostChain?.name || m.name, token: m.hostChain?.tokenAddress })),
      inputFields: [
        { dataType: InputFieldDataType.String, inputLabel: 'First Name', isRequired: true },
        { dataType: InputFieldDataType.String, inputLabel: 'Last Name', isRequired: true },
        { dataType: InputFieldDataType.Email, inputLabel: 'Email address', isRequired: true },
        { dataType: InputFieldDataType.String, inputLabel: 'Phone', isRequired: true },
        { dataType: InputFieldDataType.Address, inputLabel: 'Address', isRequired: true }
      ],
      tosUrl
    }
    return Buffer.from(JSON.stringify(data), 'utf8').toString('base64')
  }, [methods, logoPreviewUrl, bannerPreviewUrl, buttonColor, name, currency])

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  const [previewWidth, setPreviewWidth] = useState('90%')

  if (initialLoading) {
    return <LoadingBar />
  }

  return (
    <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2,
          background: 'white',
          position: 'relative'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            padding: '10px 30px',
            top: 0,
            position: 'absolute',
            background: 'rgba(255, 255, 255, 0.85)',
            width: '100%',
            zIndex: 1,
            backdropFilter: 'blur(5px)',
            boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)'
          }}>
          <IconButton
            type="button"
            style={{ padding: 5 }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate('/donation_links')
            }}>
            <Close style={{ width: 15, pointerEvents: 'none' }} />
          </IconButton>
          <h1 style={{ fontSize: 18, fontWeight: 400 }}>Edit donation link</h1>
        </div>
        <div
          style={{
            fontSize: 14,
            width: 450,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
            paddingTop: 90
          }}>
          <form
            onSubmit={onFormSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              justifyContent: 'space-between'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: 40,
                paddingTop: 0
              }}>
              <ManagedPaymentMethodSelector allowTestnets selectedMethods={methods} setSelectedMethods=
                {setSelectedMethods} />

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', gap: 5 }}>
                  <InputLabel>Name</InputLabel>
                </div>
                <TextInput onChange={(t) => setName(t.target.value as any)} value={name} placeholder="Enter name" />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Donation currency</InputLabel>
                <SearchMultiselect
                  disabled={loading}
                  placeholder="Select currency"
                  itemArray={Currencies}
                  selectedItems={Currencies.filter(c => c.ticker === currency) ?? []}
                  setSelectedItems={c => setCurrency(c[0].ticker)}
                  keyExtractor={c => c.ticker}
                  labelExtractor={c => <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{c.icon}{c.ticker}</div>}
                  isSingleSelect
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', gap: 5 }}>
                  <InputLabel>Brand images</InputLabel>
                </div>

                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    height: 150,
                    boxShadow: `0 0 3px ${RADOM_COLORS.GRAY_DARK}`,
                    borderRadius: 10
                  }}>
                  <ImageUploadButton
                    style={{
                      backgroundImage: `url(${bannerPreviewUrl || bannerImgBytes})`,
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'initial'
                    }}>
                    {!bannerImgBytes && (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <span style={{ pointerEvents: 'none', marginLeft: 20 }}>Upload</span>
                        <OptionalLabel>Optional</OptionalLabel>
                      </div>
                    )}
                    <input
                      type="file"
                      name="profile_picture"
                      ref={bannerFileInput}
                      onChange={async () =>
                        await
                        onImageFileChange(bannerFileInput, setBannerImgBytes, setBannerImageFile, setBannerPreviewUrl)}
                      style={{
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                      }}
                    />
                  </ImageUploadButton>

                  <ImageUploadButton
                    style={{
                      backgroundImage: `url(${logoPreviewUrl || logoImgBytes})`,
                      backgroundColor: 'white',
                      borderRadius: '100%',
                      position: 'absolute',
                      top: 50
                    }}>
                    {!logoImgBytes && <span style={{ pointerEvents: 'none' }}>Upload</span>}
                    <input
                      type="file"
                      name="profile_picture"
                      ref={imageFileInput}
                      onChange={async () =>
                        await onImageFileChange(imageFileInput, setLogoImgBytes, setLogoImageFile, setLogoPreviewUrl)}
                      style={{
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                      }}
                    />
                  </ImageUploadButton>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Button color</InputLabel>
                <div style={{ width: 'fit-content' }}>
                  <Dropdown
                    overflow='initial'
                    innerStyle={{ maxHeight: '100vh' }}
                    selectedContent={
                      <div style={{
                        width: 25,
                        height: 25,
                        borderRadius: 5,
                        background: buttonColor
                      }} />
                    }
                    dropdownContent={
                      <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <ColorPicker
                          value={buttonColor}
                          onChange={v => setButtonColor(v)}
                        />
                        <TextInput
                          value={buttonColor}
                          onChange={v => setButtonColor(v.target.value)}
                        />
                      </div>
                    }
                  />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Add terms of service link</InputLabel>
                <Switch
                  checked={tosUrl !== undefined}
                  onClick={() => tosUrl !== undefined ? setTosUrl(undefined) : setTosUrl('')}
                />
                {tosUrl !== undefined && (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <TextInputWithLabel
                        label="Terms of Service URL"
                        placeholder="https://"
                        value={tosUrl}
                        onChange={(e) => {
                          if ((e.target as HTMLInputElement).value) {
                            setTosUrl((e.target as HTMLInputElement).value)
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                padding: '20px 40px',
                position: 'sticky',
                bottom: 0,
                background: 'linear-gradient(to top, white, white, transparent)',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
              }}>
              <PrimaryButton style={{ marginTop: '32px', width: '100%', gap: 8 }} isLoading={loading} type="submit">
                <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                <span>Update donation link</span>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>

      <Preview
        iframeTitle="donate.radom.network"
        iframeLink={`${PAY_BASE_URL}/preview/donation#${previewData}`}
        previewWidth={previewWidth}
        onWidthChange={setPreviewWidth}
      />
    </div>
  )
}

export default EditDonationLink
