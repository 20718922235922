import { makeAutoObservable } from 'mobx'
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers'
import { Chain } from '../util/Chains'
import { errorToast, successToast } from '../util/Util'
import { Signer } from 'ethers'
import { getJsonRpcProviderByChainId } from '../util/Providers'
import { EXPLORER_URLS } from '../util/Constants'
import Radom, { IOrganization, User } from './Radom'

interface IAssumedOrg {
  chainID: number
  orgAddress: string
  roleName: string
}

const ASSUMED_ORG_LOCAL_STORAGE_KEY = 'radomAssumedOrg'

class UserState {
  private _disableRefresh = false

  isLoading = false
  isConnected = false
  selectedChainId: number
  provider: JsonRpcProvider
  _signer: Signer
  accounts: string[] = []
  assumedOrg: IAssumedOrg = {
    chainID: 1,
    orgAddress: '',
    roleName: 'Root'
  }

  _user?: User = undefined
  userFetch?: Promise<User>

  organization?: IOrganization = undefined
  organizationFetch?: Promise<IOrganization>

  constructor() {
    makeAutoObservable(this)
  }

  get selectedOrganizationId(): string | undefined {
    return this._user?.defaultOrganizationId
  }

  getUser = async (): Promise<User> => {
    if (!this._user) {
      if (!this.userFetch) {
        this.userFetch = Radom.getUser()
      }
      this._user = await this.userFetch
      const tokenStr = atob(Radom.token)
      const token = JSON.parse(tokenStr)
      if (!token.otp_verified && this._user && this._user.otpVerified && this._user.otpEnabled) {
        Radom.isLoggedIn = false
        Radom.requires2fa = true
      }
      this.userFetch = undefined
    }
    const impersonationOrg = localStorage.getItem('IMPERSONATION_ORG_ID')
    if (impersonationOrg) {
      this._user.defaultOrganizationId = impersonationOrg
    }

    return this._user
  }

  getOrganization = async(forceFetch = false): Promise<IOrganization> => {
    if (!this.organization || forceFetch) {
      const user = await this.getUser()
      if (!this.organizationFetch) {
        this.organizationFetch = Radom.getOrganization(user?.defaultOrganizationId)
      }
      this.organization = await this.organizationFetch
      this.organizationFetch = undefined
    }

    return this.organization
  }

  toggleChainRefresh = (isEnabled: boolean): void => {
    this._disableRefresh = !isEnabled
  }

  setupWalletConnection = (): void => {
    try {
      const ethereum = (window as any).ethereum
      this.provider = new Web3Provider(ethereum, 'any')
      if (ethereum) {
        ethereum.on('chainChanged', () => {
          if (this._disableRefresh) return
          window.location.reload()
        })
      }
    } catch (err) {
    }
  }

  setProvider = (provider: Web3Provider): void => {
    this.provider = provider
  }

  get connectedAccount(): Promise<string> {
    if (this.isConnected && this.accounts.length > 0) {
      return Promise.resolve(this.accounts[0])
    }
    return this.listConnectedAccounts().then(async () => await Promise.resolve(this.accounts[0]))
  }

  get rootRoleOrg(): IAssumedOrg {
    return {
      orgAddress: this.accounts[0],
      chainID: this.selectedChainId,
      roleName: 'Root'
    }
  }

  get signer(): Signer | undefined {
    if (this._signer) {
      return this._signer
    }
    if (this.provider) {
      return this.provider.getSigner()
    }
    return undefined
  }

  setAssumedOrg = (chainID: number, orgAddress: string, roleName: string): void => {
    this.assumedOrg = {
      chainID,
      orgAddress,
      roleName
    }
    localStorage.setItem(ASSUMED_ORG_LOCAL_STORAGE_KEY, JSON.stringify(this.assumedOrg))
  }

  listConnectedAccounts = async (): Promise<void> => {
    if (!this.signer) {
      this.isLoading = false
      return
    }

    try {
      this.accounts = [await this.signer.getAddress()]
      if (this.accounts.length <= 0) {
        this.isLoading = false
        return
      }

      this.selectedChainId = (await this.provider.getNetwork()).chainId
      const assumedOrg = localStorage.getItem(ASSUMED_ORG_LOCAL_STORAGE_KEY)
      if (assumedOrg) {
        this.assumedOrg = JSON.parse(assumedOrg)
      } else {
        this.assumedOrg = {
          orgAddress: this.accounts[0],
          chainID: this.selectedChainId,
          roleName: 'Root'
        }
      }
      this.isConnected = this.accounts.length > 0
    } catch (err) {
      console.error('Failed to load connected accounts', err)
    }
    this.isLoading = false
  }

  connect = async (): Promise<void> => {
    if (!(window as any).ethereum) {
      errorToast('No ethereum browser wallet available or installed!')
      return
    }

    try {
      this.isLoading = true
      await this.provider.send('eth_requestAccounts', [])
      await this.listConnectedAccounts()
    } catch (err) {
      errorToast(err.reason || err.message)
    }
    this.isLoading = false
  }

  switchChain = async (chain: Chain): Promise<void> => {
    if (chain.chainId === this.selectedChainId) {
      return
    }

    try {
      this.isLoading = true

      if (this.provider instanceof Web3Provider) {
        await this.provider.send('wallet_switchEthereumChain', [{
          chainId: '0x' + chain.chainId.toString(16)
        }])
        this.selectedChainId = (await this.provider.getNetwork()).chainId
      } else {
        this._signer = this._signer.connect(getJsonRpcProviderByChainId(chain.chainId))
        this.selectedChainId = chain.chainId
      }
      this.isLoading = false
      successToast(`Switched chain to ${chain.name}!`)
    } catch (switchError) {
      try {
        await this.provider.send('wallet_addEthereumChain', [{
          chainId: '0x' + chain.chainId.toString(16),
          chainName: chain.name,
          rpcUrls: [chain.rpcURL],
          blockExplorerUrls: [EXPLORER_URLS[chain.name]],
          nativeCurrency: {
            name: chain.symbol,
            symbol: chain.symbol,
            decimals: 18
          }
        }])
      } catch (err) {
        console.error(err)
        this.isLoading = false
        throw err
      }
      this.isLoading = false
    }
  }
}

export default new UserState()
