import { observer } from 'mobx-react'
import React, { ReactElement, useRef, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import { TextInputWithLabel } from '../../components/Input'
import { PrimaryButton } from '../../components/Button'
import User from '../../state/User'

const VerificationForm = (): ReactElement => {
  return <>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px'
    }}>

      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>

        <div style={{ display: 'flex', gap: 20 }}>

          <div style={{ flexGrow: 1 }}>
            <TextInputWithLabel
              label='First name'
              placeholder='Enter first name'
              disabled={false}
              required
              value={''}
              onChange={e => {
                const el = (e.target as HTMLInputElement)
                const value = el.value
              }}
            />
          </div>

          <div style={{ flexGrow: 1 }}>
            <TextInputWithLabel
              label='Last name'
              placeholder='Enter last name'
              disabled={false}
              required
              value={''}
              onChange={e => {
                const el = (e.target as HTMLInputElement)
                const value = el.value
              }}
            />
          </div>

        </div>

        <TextInputWithLabel
          label='Email address'
          placeholder='Enter email address'
          disabled={false}
          required
          value={User._user?.emailAddress}
          onChange={e => {
            const el = (e.target as HTMLInputElement)
            const value = el.value
          }}
        />

        <TextInputWithLabel
          label='Street name'
          placeholder='Enter street name'
          disabled={false}
          required
          value={''}
          onChange={e => {
            const el = (e.target as HTMLInputElement)
            const value = el.value
          }}
        />

        <TextInputWithLabel
          label='Name'
          placeholder='Enter a name for this account'
          disabled={false}
          required
          value={''}
          onChange={e => {
            const el = (e.target as HTMLInputElement)
            const value = el.value
          }}
        />

      </div>

    </div>

    <div style={{
      padding: 30,
      borderRadius: 15,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }}>
      <PrimaryButton
        disabled={false}
        isLoading={false}
        style={{ width: '100%' }}
        onClick={() => {}}>
            Submit
      </PrimaryButton>
    </div>
  </>
}

const FiatVerificationModal = observer((props: ModalProps): ReactElement => {
  const [showIFrame, setShowIFrame] = useState(true)

  const iframeRef = useRef(null)

  return <Modal {...props} innerStyles={{ width: 550 }}>

    {
      showIFrame &&
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: 350, border: 0 }}
        onLoad={(a) => {
          try {
            const path = (iframeRef.current as any).contentWindow?.location?.href
            if (path.startsWith('http://localhost:1234')) {
              setShowIFrame(false)
            }
          } catch (err) {

          }
        }}
        src='https://dashboard.bridge.xyz/accept-terms-of-service?session_token=2d993b31-29c2-470e-965b-eb2671bce8bc&redirect_uri=http://localhost:1234'
      />
    }

    {
      !showIFrame &&
      <VerificationForm />
    }
  </Modal>
})

export default FiatVerificationModal
