import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { IconButton, PrimaryButton } from '../components/Button'
import Table from '../components/Table'
import Radom, { IPaymentLink } from '../state/Radom'
import { Link, useNavigate } from 'react-router-dom'
import { RADOM_COLORS } from '../util/Constants'
import { errorToast, infoToast, SecondsToPaymentPeriod, successToast } from '../util/Util'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import { TextInput } from '../components/Input'
import Copy from '../icons/Copy'
import PageHeader from '../components/PageHeader'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import LinkExternal from '../components/LinkExternal'
import TimeText from '../components/TimeText'
import { Currencies } from '../util/Currencies'
import { Container } from '../components/Animations'
import PaymentMethodDisplay from '../components/PaymentMethodDisplay'

const CloseFns = {}

const Presales = observer((): React.ReactElement => {
  const navigate = useNavigate()
  const [presales, setPresales] = useState<IPaymentLink[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const inputRefs = {}

  useEffect(() => {
    loadPresales()
  }, [])

  const loadPresales = (): void => {
    setIsLoading(true)
    Radom.listPaymentLinks(['presale'])
      .then(paymentLinks => {
        setPresales(paymentLinks.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()))
      })
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoading(false))
  }

  return <Container>
    <div style={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageHeader title='Presales' subTitle='Create and manage your presale links' />
        <Link key="createPresale" to="/presales/create" style={{ textDecoration: 'none' }}>
          <PrimaryButton style={{ gap: 8 }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create presale</span>
          </PrimaryButton>
        </Link>
      </div>
      <div style={{ paddingTop: 20 }}>
        <Table
          borderless
          headers={[
            <span key="URL">URL</span>,
            <span key="products">Product</span>,
            <span key="tokens ">Payment methods</span>,
            <span key="created">Created</span>,
            <span key="action"></span>
          ]}
          isLoading={isLoading}
          items={presales.map(p => [
            <div key="url" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
              <TextInput ref={r => r && (inputRefs[p.url] = r)} value={p.url} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
              <IconButton style={{ padding: 0 }}>
                <Copy style={{ width: 20 }} onClick={() => {
                  inputRefs[p.url].select()
                  inputRefs[p.url].setSelectionRange(0, 99999)
                  navigator.clipboard.writeText(inputRefs[p.url].value)
                  infoToast('Copied presale!')
                }} />
              </IconButton>
              <IconButton style={{ padding: 0 }} onClick={() => window.open(p.url, '_blank')} >
                <LinkExternal stroke={RADOM_COLORS.GRAY_DARK} width={20} />
              </IconButton>
            </div>,
            <div key="products" style={{ display: 'flex', flexDirection: 'column', gap: 10, flexWrap: 'wrap' }}>
              {
                p.products
                  .map(p =>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 5,
                      gap: 5,
                      padding: '5px 10px'
                    }} key={p.id}>
                      {p.imageUrl && <img src={p.imageUrl} style={{ width: 20, height: 'auto' }} />}
                      <span style={{ maxWidth: 100, whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}>{p.name}</span>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                        {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                        <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                      </div>
                      {
                        p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                        <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                          per {SecondsToPaymentPeriod[p.chargingIntervalSeconds].toLowerCase()}
                        </span>
                      }
                    </div>
                  )
              }
            </div>,
            <div key="paymentMethods" style={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
              {
                p.gateway.managed?.methods.slice(0, 3).map((m, i) =>
                  <PaymentMethodDisplay
                    key={i}
                    method={m}
                    excludeName
                    disableTooltip={false} />)
              }
              {
                p.gateway.managed &&
                p.gateway.managed.methods.length > 3 &&
                <span style={{ marginLeft: 4 }}>+{p.gateway.managed.methods.length - 3}</span>
              }
            </div>,
            <div key="created" style={{ color: RADOM_COLORS.GRAY_DARKER }}>
              <TimeText date={new Date(p.createdAt)} />
            </div>,
            <div key="dots" style={{ display: 'flex' }}>
              <Dropdown
                key={p.id}
                noChevron
                selectedContent={<Dots width={15} />}
                onCloseFn={f => {
                  CloseFns[p.id] = f
                }}
                dropdownContent={
                  <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                    <DropdownItem onClick={() => navigate(`/presales/${p.id}`)}>Edit presale</DropdownItem>
                    <ConfirmButtonModal
                      title='Delete presale'
                      description='Are you sure you want to delete this presale? This action cannot be undone.'
                      action='Delete presale'
                      onConfirm={async () => {
                        await Radom.deletePaymentLink(p.id)
                          .then(() => {
                            successToast('Successfully deleted presale')
                            loadPresales()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props => <DropdownItem {...props}>Delete presale</DropdownItem>}
                    />

                    {
                      p.isPresale &&
                      <DropdownItem
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `<iframe allow="clipboard-write" style="outline: 0; border: 0; width: 450px; height: 700px;" src="${p.url}" />`
                          )
                          successToast('Embed code copied')
                          CloseFns[p.id]?.()
                        }}>
                        Copy embed code
                      </DropdownItem>
                    }
                  </div>
                }
              />
            </div>
          ])}
          emptyPlaceholderText='No presales created yet'
        />
      </div>
    </div>
  </Container>
})

export default Presales
