import React, { ReactElement, useRef, useState } from 'react'
import Dropdown, { DropdownContentHeader, DropdownItem, DropdownTextInput } from './Dropdown'
import Spinner from './Spinner'
import Checkbox from './Checkbox'
import { InputLabel } from './Input'
import { ManagedPaymentMethod, getPaymentMethods } from '../util/Managed'
import Tooltip from './Tooltip'
import { isEqual } from 'lodash'
import Close from '../icons/Close'
import { RADOM_COLORS } from '../util/Constants'
import { IconButton } from './Button'
import Switch from './Switch'
import { SelectedMethodIcon } from './PaymentMethodDisplay'

interface IProps {
  disabled?: boolean
  header?: ReactElement
  placeholderText?: string
  selectedMethods: ManagedPaymentMethod[]
  setSelectedMethods: React.Dispatch<React.SetStateAction<ManagedPaymentMethod[]>>
  isLoading?: boolean
  allowTestnets?: boolean
  testnetOnly?: boolean
  label?: string
  allowedNetwork?: string
}

export const ManagedPaymentMethodSelector = (props: IProps): ReactElement => {
  const [query, setQuery] = useState('')
  const dropdownRef = useRef<Dropdown>(null)
  const queryInputRef = useRef<HTMLInputElement>(null)

  const setDropdown = (open: boolean): void => {
    dropdownRef.current?.setState({ open })
  }

  const onQueryChange = (e: React.ChangeEvent): void => {
    const val = (e.target as HTMLInputElement).value
    if (val !== '') {
      setDropdown(true)
    }
    setQuery(val)
  }

  const [testnetMode, setTestnetMode] = useState(false)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }}>
      <InputLabel>{props.label ?? 'Payment methods'}</InputLabel>
      <Dropdown
        disabled={props.disabled}
        preferredAlignment='right'
        selectedContent={
          <>
            { props.isLoading && <Spinner /> }
            {
              props.selectedMethods.length === 0 &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.placeholderText ?? 'Select methods'}
              </div>
            }
            {
              !props.isLoading && props.selectedMethods.length > 0 &&
              <div style={{
                display: 'flex',

                alignItems: 'center',
                gap: 8,
                flexWrap: 'wrap'
              }}>
                {
                  props.selectedMethods.map((method, i) =>
                    <SelectedMethodIcon key={i} method={method} />
                  )
                }
              </div>
            }
          </>
        }
        dropdownContent={
          <div>
            <DropdownContentHeader style={{
              borderBottom: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
              zIndex: 1,
              position: 'sticky',
              top: 0,
              background: 'white'
            }}>
              <DropdownTextInput
                autoFocus
                ref={queryInputRef}
                type="text"
                value={query}
                onChange={onQueryChange}
                placeholder={'Search...'}
              />
              {
                query !== '' &&
                  <IconButton
                    style={{ position: 'absolute', right: 10, padding: 5 }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setQuery('')
                    }}>
                    <Close style={{ width: 10, pointerEvents: 'none' }} />
                  </IconButton>
              }
            </DropdownContentHeader>

            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
              <div style={{ overflow: 'scroll' }}>
                {getPaymentMethods()
                  .filter(m => !m.isHidden)
                  .filter(m => {
                    if (!query) return true
                    const lowercaseQuery = query.toLowerCase()
                    return m.name.toLowerCase().includes(lowercaseQuery) ||
                          m.ticker.toLowerCase().includes(lowercaseQuery) ||
                          m.hostChain?.name.toLowerCase().includes(lowercaseQuery)
                  })
                  .filter(t => testnetMode || props.testnetOnly ? t.isTestnet : !t.isTestnet)
                  .map((p, i) => {
                    let disabled = p.isDisabled
                    if (props.allowedNetwork) {
                      if (p.hostChain) {
                        if (p.hostChain.name !== props.allowedNetwork) {
                          disabled = true
                        }
                      } else if (p.name !== props.allowedNetwork) {
                        disabled = true
                      }
                    }

                    return <DropdownItem
                      key={i}
                      style={{ display: 'flex', alignItems: 'center', gap: 10, opacity: disabled ? 0.25 : 1 }}
                      onClick={e => {
                        if (disabled) {
                          return
                        }

                        if (props.selectedMethods.find(c => isEqual(c, p))) {
                          props.setSelectedMethods(props.selectedMethods.filter(c => !isEqual(c, p)))
                        } else {
                          props.setSelectedMethods([...new Set([...props.selectedMethods, p])])
                        }
                        e.stopPropagation()
                      }}>
                      <Checkbox size={16} checked={!!props.selectedMethods.find(cc => isEqual(cc, p))} />
                      <div style={{
                        width: 25,
                        height: 25,
                        backgroundImage: `url(${p.logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                      }}>
                        {
                          p.hostChain && <div style={{
                            background: 'rgba(255, 255, 255, 0.95)',
                            borderRadius: '100%',
                            width: 15,
                            height: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: -1,
                            right: -5,
                            boxShadow: '0 0 1px'
                          }}>
                            <div style={{
                              width: 10,
                              height: 10,
                              backgroundImage: `url(${p.hostChain.logo})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat'
                            }} />
                          </div>
                        }
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <span>{p.name}</span>
                        <span style={{ opacity: 0.5 }}>{p.ticker}</span>
                        {p.isDisabled && <span style={{
                          background: RADOM_COLORS.GRAY_DARK,
                          color: 'black',
                          textTransform: 'uppercase',
                          fontSize: 10,
                          padding: '2px 8px',
                          borderRadius: 5
                        }}>Coming Soon</span>}
                      </div>
                    </DropdownItem>
                  })}
              </div>
            </div>

            {
              !props.testnetOnly && props.allowTestnets &&
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'sticky',
                bottom: 0,
                background: 'linear-gradient(transparent, rgba(255, 255, 255, 0.9) 50%, white)'
              }}>
                <div
                  onClick={() => {
                    setTestnetMode(!testnetMode)
                    props.setSelectedMethods([])
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 5,
                    padding: 10,
                    cursor: 'pointer',
                    userSelect: 'none'
                  }}>
                  <Switch checked={testnetMode} sizeScale={0.5} />
                  <span style={{
                    color: RADOM_COLORS.GRAY_DARKER,
                    fontSize: 12,
                    textTransform: 'uppercase'
                  }}>Testnet mode</span>
                  <Tooltip message='Use testnets to validate your checkout before moving to production.' />
                </div>
              </div>
            }
          </div>
        }
      />
    </div>
  )
}
