import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import toast, { Renderable, ValueOrFunction } from 'react-hot-toast'
import UserState from '../state/User'

export const shortAddress = (address: string, decimals = 6): string => {
  if (!address) {
    return ''
  }

  return address.substring(0, decimals) + '...' + address.slice(address.length - decimals)
}

export const SecondsToPaymentPeriod = {
  1: 'Second',
  60: 'Minute',
  [60 * 60]: 'Hour',
  [60 * 60 * 24]: 'Day',
  [60 * 60 * 24 * 7]: 'Week',
  [60 * 60 * 24 * 30]: 'Month',
  [60 * 60 * 24 * 30 * 3]: 'Quarter',
  [60 * 60 * 24 * 365]: 'Year'
}

export const formatSubscriptionDuration = (numSeconds: number): string => {
  if (SecondsToPaymentPeriod[numSeconds]) {
    return SecondsToPaymentPeriod[numSeconds]
  }

  return `${numSeconds} seconds`
}

export function convertDateToUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function errorToast(message: string): void {
  toast.error(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export function successToast(message: string): void {
  toast.success(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export function infoToast(message: string): void {
  toast(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export async function promiseToast<T>(
  promise: Promise<T>,
  loading: string,
  success: string,
  error: ValueOrFunction<Renderable, any>
): Promise<T> {
  return await toast.promise(
    promise,
    { loading, success, error }, {
      position: 'top-center',
      style: {
        fontSize: 14
      }
    })
}

export function formatCurrency(amount: number, ticker: string): string {
  try {
    return Intl.NumberFormat('en-US', { currency: ticker, style: 'currency' }).format(amount)
  } catch (e) {
    return `${Intl.NumberFormat('en-US').format(amount)} ${ticker}`
  }
}

export function simpleEmailValidation(address: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(address)
}

function setLocalStorageItemWithEvent(key: string, value?: string): void {
  if (!value) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }

  dispatchEvent(new Event('localStorageItemSet'))
}

const TEST_MODE_KEY = 'testMode'
export function useTestMode(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [testMode, setTestMode] = useState(!!localStorage.getItem(TEST_MODE_KEY))

  useEffect(() => {
    addEventListener('localStorageItemSet', () => {
      const newTestMode = !!localStorage.getItem(TEST_MODE_KEY)
      setTestMode(newTestMode)
    })
  }, [])

  useEffect(() => {
    setLocalStorageItemWithEvent(TEST_MODE_KEY, testMode ? 'true' : undefined)
  }, [testMode])

  return [testMode, setTestMode]
}

export async function identifyVisitor(): Promise<void> {
  try {
    const user = await UserState.getUser()
    const visitorToken = localStorage.getItem('visitorToken')

    if (user?.emailAddress && visitorToken) {
      window.hsConversationsSettings = {
        identificationEmail: user.emailAddress,
        identificationToken: visitorToken
      }

      if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.load()
      } else {
        console.error('HubSpotConversations is not available on the window object.')
      }
    } else {
      console.error('User email address or visitor token is not available.')
    }
  } catch (error) {
    console.error('Error identifying visitor:', error)
  }
}
