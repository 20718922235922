import React from 'react'
import styled from 'styled-components'
import { Tokens } from './Tokens'
import BTCLogo from '../images/BTCLogo.svg'
import ETHLogo from '../images/EthLogo.svg'

export interface Currency {
  icon: React.ReactElement
  ticker: string
}

const CurrencySymbol = styled.img`
  height: 15px;
  width: auto;
`

export const Currencies: Currency[] = [
  {
    icon: <div>$</div>,
    ticker: 'USD'
  },
  {
    icon: <div>$</div>,
    ticker: 'CAD'
  },
  {
    icon: <div>£</div>,
    ticker: 'GBP'
  },
  {
    icon: <div>€</div>,
    ticker: 'EUR'
  },
  {
    icon: <div>د.إ</div>,
    ticker: 'AED'
  },
  {
    icon: <div>₹</div>,
    ticker: 'INR'
  },
  {
    icon: <div>kr</div>,
    ticker: 'SEK'
  },
  {
    icon: <div>R$</div>,
    ticker: 'BRL'
  },
  {
    icon: <div>$</div>,
    ticker: 'NZD'
  },
  {
    icon: <div>$</div>,
    ticker: 'AUD'
  },
  {
    icon: <div>$</div>,
    ticker: 'HKD'
  },
  {
    icon: <div>kr</div>,
    ticker: 'NOK'
  },
  {
    icon: <div>kr.</div>,
    ticker: 'DKK'
  },
  {
    icon: <div>¥</div>,
    ticker: 'JPY'
  },
  {
    icon: <div>¥</div>,
    ticker: 'CNY'
  },
  {
    icon: <div>fr.</div>,
    ticker: 'CHF'
  },
  {
    icon: <div>$</div>,
    ticker: 'SGD'
  },
  {
    icon: <div>zł</div>,
    ticker: 'PLN'
  },
  {
    icon: <div>$</div>,
    ticker: 'MXN'
  },
  {
    icon: <div>Kč</div>,
    ticker: 'CZK'
  },
  {
    icon: <div>Ft</div>,
    ticker: 'HUF'
  },
  {
    icon: <div>R</div>,
    ticker: 'ZAR'
  },
  {
    icon: <div>﷼</div>,
    ticker: 'SAR'
  },
  {
    icon: <div>₽</div>,
    ticker: 'RUB'
  },
  {
    icon: <div>₺</div>,
    ticker: 'TRY'
  },
  {
    icon: <div>₪</div>,
    ticker: 'ILS'
  },
  {
    icon: <CurrencySymbol src={Tokens.USDC.logo} />,
    ticker: 'USDC'
  },
  {
    icon: <CurrencySymbol src={Tokens.USDT.logo} />,
    ticker: 'USDT'
  },
  {
    icon: <CurrencySymbol src={BTCLogo as string} />,
    ticker: 'BTC'
  },
  {
    icon: <CurrencySymbol src={ETHLogo as string} />,
    ticker: 'ETH'
  }
]
