import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IProduct, CheckoutItemData, ManagedPaymentDetails, IPaymentMethod } from '../state/Radom'
import { RADOM_COLORS } from '../util/Constants'
import { Currencies } from '../util/Currencies'
import { getMethod, getMethodLogoByTicker } from '../util/Managed'
import { SecondsToPaymentPeriod, shortAddress } from '../util/Util'
import { IconLinkButton } from './Button'
import LinkExternal from './LinkExternal'
import Tooltip from './Tooltip'
import Table from './Table'
import { formatExplorerUrl } from '../util/Chains'
import { ExternalLinkText } from '../views/Events'
import TimeText from './TimeText'
import { Link } from 'react-router-dom'
import PaymentMethodDisplay from './PaymentMethodDisplay'

export const SubTitle = styled.div`
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  display: flex;
  align-items: center;
  gap: 3px;
`

export const Section = styled.div`
  padding: 30px;
  border-radius: 10px;
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  boxShadow: 0 0 3px ${RADOM_COLORS.GRAY_DARK};
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const SectionContent = styled.div<{ numColumns?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ numColumns }) => numColumns || 2}, 1fr);
  flex-wrap: wrap;
  gap: 30px;
`

export const SectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const SectionBreaker = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed ${RADOM_COLORS.GRAY_DARK};
`

export const ProductThumbnail = ({ p }: { p: IProduct }): ReactElement => {
  return <IconLinkButton
    to={`/products/${p.id}`}
    target='_blank'
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      textDecoration: 'none',
      color: 'black',
      width: 'fit-content',
      padding: 0
    }}>
    <div style={{
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      gap: 8
    }}>
      {p.imageUrl && <img src={p.imageUrl} style={{ width: 20, borderRadius: 5, height: 'auto' }} /> }
      {p.name}
    </div>
    <div style={{
      display: 'flex',
      fontSize: 14,
      columnGap: 5
    }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
        <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
      </div>
      {
        p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
        <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
          per {SecondsToPaymentPeriod[p.chargingIntervalSeconds].toLowerCase()}
        </span>
      }
    </div>
    <LinkExternal stroke={RADOM_COLORS.GRAY_DARKER} width={15} style={{ marginBottom: -1 }} />
  </IconLinkButton>
}

export const ItemDataThumbnail = ({ p }: { p: CheckoutItemData }): ReactElement => {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    textDecoration: 'none',
    color: 'black',
    width: 'fit-content'
  }}>
    <div style={{
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      gap: 8
    }}>
      {p.imageUrl && <img src={p.imageUrl} style={{ width: 20, borderRadius: 5, height: 'auto' }} /> }
      {p.name}
    </div>
    <div style={{
      display: 'flex',
      fontSize: 14,
      columnGap: 5
    }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
        <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(Number(p.price))}</span>
      </div>
      {
        p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
      <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
        per {SecondsToPaymentPeriod[p.chargingIntervalSeconds].toLowerCase()}
      </span>
      }
    </div>

  </div>
}

export const PaymentNetwork = ({ payment }: { payment: ManagedPaymentDetails }): ReactElement => {
  const paymentMethod = getMethod(payment.network, payment.token)

  return <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
    <img src={paymentMethod.hostChain?.logo ?? paymentMethod.logo} style={{ height: 20, width: 'auto' }} />
    <span>
      { paymentMethod.hostChain?.name ?? paymentMethod.name }
    </span>
  </div>
}

export const PaymentAsset = ({ payment }: { payment: ManagedPaymentDetails }): ReactElement => {
  const paymentMethod = getMethod(payment.network, payment.token)

  return <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
    <img src={paymentMethod.logo} style={{ height: 20, width: 'auto' }} />
    <span>
      { paymentMethod.currencyName ?? paymentMethod.name }
    </span>
  </div>
}

export const PaymentAssetIcon = ({ payment }: { payment: IPaymentMethod }): ReactElement => {
  const paymentMethod = getMethod(payment.network, payment.token)
  return <img src={paymentMethod.logo} style={{ height: 15, width: 'auto' }} />
}

export const PaymentDetailsSection = ({ payment, netPayment, short }: {
  payment: ManagedPaymentDetails
  netPayment: number
  short?: boolean
}): ReactElement =>
  <Section>
    {
      !short &&
      <SubTitle style={{ fontSize: 18 }}>
        Payment details
      </SubTitle>
    }

    <SectionContent>
      {
        short &&
        payment.paymentEventId &&
        <SectionItem>
          <SubTitle>Id</SubTitle>
          <Link to={`/payment/${payment?.paymentEventId}`} style={{ fontSize: 14, color: RADOM_COLORS.BLUE_MED }}>
            <span>{payment.paymentEventId}</span>
          </Link>
        </SectionItem>
      }

      <SectionItem>
        <SubTitle>Asset</SubTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <PaymentMethodDisplay
            style={{ marginRight: 5 }}
            method={{ network: payment.network, token: payment.token }}
            includeNetworkName
            disableTooltip={false} />
        </div>
      </SectionItem>

      <SectionItem>
        <SubTitle>
          <span>Gross payment</span>
          <Tooltip message='The total payment amount expected to be paid by the customer.' />
        </SubTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <PaymentAssetIcon payment={payment} />
          <span>{Number(payment.amount)}</span>
        </div>
      </SectionItem>

      <SectionItem>
        <SubTitle>
          <span>Net payment</span>
          <Tooltip message='The amount you receive after fee deduction.' />
        </SubTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <PaymentAssetIcon payment={payment} />
          <span>{netPayment}</span>
        </div>
      </SectionItem>

      {
        payment.networkFee &&
        <SectionItem>
          <SubTitle>Network fee</SubTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <PaymentAssetIcon payment={payment} />
            <span>{payment.networkFee}</span>
          </div>
        </SectionItem>
      }

      <SectionItem>
        <SubTitle>Radom fee</SubTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <PaymentAssetIcon payment={payment} />
          <span>{
            Number(payment.amount) <= Number(payment.networkFee ?? 0)
              ? 0
              : new Intl.NumberFormat('en-US', { maximumSignificantDigits: 8 }).format(
                Number(payment.amount) - netPayment - Number(payment.networkFee ?? 0)
              )
          }</span>
        </div>
      </SectionItem>
    </SectionContent>

    {
      !short &&
      <SectionBreaker />
    }

    {
      !short &&
      payment.conversionRates.length > 0 &&
          <div>
            <SubTitle style={{ fontSize: 14, paddingBottom: 10 }}>Conversion rates</SubTitle>
            {
              payment.conversionRates.map((d, i) =>
                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: 5, fontSize: 14 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <span>1</span>
                    {
                      getMethodLogoByTicker(d.from) &&
                      <img src={getMethodLogoByTicker(d.from)} style={{ width: 15, height: 'auto' }} />
                    }
                    {d.from}
                  </div>
                  <div>=</div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <span>{Number(d.rate)}</span>
                    {
                      getMethodLogoByTicker(d.to) &&
                      <img src={getMethodLogoByTicker(d.to)} style={{ width: 15, height: 'auto' }} />
                    }
                    {d.to}
                  </div>
                </div>
              )}

          </div>
    }

    {
      payment.transactions.length > 0 &&
          <div>
            <SubTitle style={{ fontSize: 14, paddingBottom: 10 }}>Payment transactions</SubTitle>
            <Table
              disableHoverStyle
              headers={[
                <span key="key">Transaction hash</span>,
                <span key="network">Network</span>,
                <span key="amount">Asset</span>,
                <span key="date">Date</span>
              ]}
              items={
                payment.transactions.map(d => [
                  <div key="1">
                    <ExternalLinkText
                      onClick={e => e.stopPropagation()}
                      target="_blank" rel="noreferrer"
                      href={formatExplorerUrl(
                        d.network,
                        'tx',
                        d.transactionHash ?? ''
                      )}>
                      {shortAddress(d.transactionHash ?? '', 18)}
                      <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                    </ExternalLinkText>
                  </div>,
                  <div key="4" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <PaymentNetwork payment={{ network: d.network, token: d.token } as any} />
                  </div>,
                  <div key="2" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    {
                      payment &&
                      <PaymentAssetIcon payment={payment} />
                    }
                    <span>{d.amount}</span>
                  </div>,
                  <TimeText key="3" date={new Date(d.blockTimestamp)} />
                ])
              }
              isLoading={false}
            />
          </div>
    }
  </Section>
